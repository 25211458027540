


























































































































































































































import { Component, Vue } from 'vue-property-decorator';

import {
  dispatchDeleteYtTask,
  dispatchGetYtTasks,
  dispatchKillYtTask,
  dispatchRunYtTask,
  dispatchStatYtTasks,
  dispatchDownloadYtTaskResult,
  dispatchDownloadYtTaskFile,
} from '@/store/yt-tasks/actions';
import { readYtTasks } from '@/store/yt-tasks/getters';
import { YT_TASK_UPDATE_RATE } from '../../../constants';
import { estimateTimeToGo } from '../utils';
import { IYtTask, IYtTaskStats } from '@/interfaces/yt-tasks';
import ProgressBar from '@/components/ProgressBar.vue';
import TooltipBase from '@/components/TooltipBase.vue';

@Component({
  components: {
    ProgressBar,
    TooltipBase,
  },
})
export default class YouTubeTasks extends Vue {
  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'left',
    },
    {
      text: 'Имя',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Описание',
      sortable: true,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Менеджер',
      sortable: true,
      value: 'manager',
      align: 'left',
    },
    {
      text: 'Глубина',
      sortable: true,
      value: 'deep',
      align: 'left',
    },
    {
      text: 'Создано',
      sortable: true,
      value: 'date_create',
      align: 'left',
    },
    {
      text: 'Завершено',
      sortable: true,
      value: 'date_end',
      align: 'left',
    },
    {
      text: 'Статус',
      sortable: true,
      value: 'status',
      align: 'left',
    },
    {
      text: 'Действия',
      value: 'id',
    },
  ];

  public updateTimer?: number = undefined;

  get tasks() {
    return readYtTasks(this.$store);
  }

  public tableLoaded = false;

  public async mounted() {
    await dispatchGetYtTasks(this.$store);
    await dispatchStatYtTasks(this.$store);
    this.tableLoaded = true;

    this.updateTimer = setInterval(async () => {
      await dispatchGetYtTasks(this.$store);
      await dispatchStatYtTasks(this.$store);
      }, YT_TASK_UPDATE_RATE,
    );
  }

  public destroyed() {
    clearInterval(this.updateTimer);
    this.updateTimer = undefined;
  }

  public async deleteTask(taskId: number) {
    await dispatchDeleteYtTask(this.$store, {id: taskId});
    await dispatchGetYtTasks(this.$store);
  }

  public async runTask(taskId: number) {
    await dispatchRunYtTask(this.$store, {id: taskId});
  }

  public async killTask(taskId: number) {
    await dispatchKillYtTask(this.$store, {id: taskId});
  }

  public async downloadYtTaskResult(taskId: number, result: string) {
    await dispatchDownloadYtTaskResult(this.$store, { id: taskId, result });
  }

  public async downloadYtTaskFile(taskFile: string) {
    await dispatchDownloadYtTaskFile(this.$store, { taskFile });
  }

  public showEstimateTimeToGo(total: number, started: number, current: number, startedTime: Date) {
    if (current >= total || started === undefined || startedTime === undefined) return '';
    return `Еще ${estimateTimeToGo(total, started, current, startedTime)}`;
  }

  public getPercent(statData: IYtTaskStats, divider = 'found_count', divisible = 'total_count') {
    if (!statData[divisible]) return 0;
    return Math.floor(statData[divider] / statData[divisible] * 100);
  }

  public isAnalyzeSubs(item: IYtTask) {
    return item.extra_stats && item.extra_stats.analyze_subs;
  }

  public isAnalyzeAudio(item: IYtTask) {
    return item.extra_stats && item.extra_stats.analyze_audio;
  }

  public isCollectBooks(item: IYtTask) {
    return item.extra_stats && item.extra_stats.collect_books;
  }

}
